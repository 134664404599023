import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';
import {Field, Form} from 'react-final-form';

import DoneIcon from '@mui/icons-material/Done';
import {Button, Checkbox, Dialog, Grid, TextField, Typography} from '@mui/material';
import {FormApi} from 'final-form';

import {CONTACTS_MODAL} from '../../../constants/constants';
import {useModalContext} from '../../../system/context/ModalContext';
import {sendRequestToChat} from '../../../system/requests/telegram';

import styles from './ContactsForm.module.scss';

type Props = {
    title?: string;
    btnText?: string;
    pageName?: string;
};

export function ContactsForm({title = '', btnText = 'Отправить', pageName = 'Главная'}: Props) {
    return (
        <div className={styles.card}>
            <TextField className={styles.input} id="name" label="Имя" variant="outlined" color="secondary"/>
            <TextField className={styles.input} id="phone" label="Телефон" variant="outlined" color="secondary"/>
            <div className={styles.btnWrap}>
                <Button
                    className={styles.button}
                    variant="contained"
                    size="large"
                    color={isMobile ? 'primary' : 'secondary'}
                    disableElevation
                >
                    {btnText}
                </Button>
            </div>
        </div>
    );
}

export function ContactsFormModal() {
    const {openedKey, closeModal, data} = useModalContext();
    const {title = 'Оформить заявку', btnText = 'Отправить', pageName = 'Главная', msgDsc = '-'} = data || {};

    const [success, setSuccess] = useState(false);
    const [isPermissionToProcess, setPermissionToProcess] = useState(true);

    const contactsOpen = openedKey === CONTACTS_MODAL;

    const onSubmit = async (values: any, form?: FormApi) => {
        setSuccess(true);

        const text = `<b>Заявка с сайта</b>\n\nИмя: ${values.name}\nНомер: ${values.phone}\nСтраница: ${pageName}\nПояснение: ${msgDsc}`;
        await sendRequestToChat(text);
    };

    const handleCloseModal = () => {
        setTimeout(() => setSuccess(false), 200);
        closeModal();
    };

    const required = value => (value ? undefined : 'Это поле необходимо заполнить');

    return (
        <Dialog open={contactsOpen} onClose={handleCloseModal}>
            <div className={styles.modal} style={success ? {background: '#f9e91a'} : {background: 'white'}}>
                {!success ? (
                    <div>
                        <h1 className={styles.modalTitle}>{title}</h1>
                        <Form
                            onSubmit={onSubmit}
                        >
                            {({handleSubmit, form, submitting, pristine, values, submitFailed}) => (
                                <form onSubmit={handleSubmit}>
                                    <Field
                                        name="name"
                                        validate={required}
                                    >
                                        {({input, meta}) => (
                                            <TextField
                                                className={styles.modalInput}
                                                id="name"
                                                label="Имя"
                                                variant="outlined"
                                                color="secondary"
                                                inputProps={input}
                                                error={submitFailed && !!meta.error}
                                                helperText={submitFailed ? meta.error : ''}
                                            />
                                        )}
                                    </Field>
                                    <Field
                                        name="phone"
                                        validate={required}
                                    >
                                        {({input, meta}) => (
                                            <TextField
                                                className={styles.modalInput}
                                                id="phone"
                                                label="Телефон"
                                                variant="outlined"
                                                color="secondary"
                                                inputProps={input}
                                                error={submitFailed && !!meta.error}
                                                helperText={submitFailed ? meta.error : ''}
                                            />
                                        )}
                                    </Field>
                                    <Grid container alignItems="center" marginTop="-8px" sx={{userSelect: 'none'}}>
                                        <Grid item width="50px" marginLeft="-12px">
                                            <Checkbox
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 32}}}
                                                value={isPermissionToProcess}
                                                defaultChecked
                                                onChange={(event) => setPermissionToProcess(event.target.checked)}
                                            />
                                        </Grid>
                                        <Grid item flex="1">
                                            <Typography fontSize={12} lineHeight={1.2}>
                                                <span>Нажимая кнопку «{btnText}», я даю свое </span>
                                                <a className={styles.link} href="/consent-personal" target="_blank">
                                                    согласие на обработку персональных данных
                                                </a>,
                                                <span> в соответствии с </span>
                                                <a className={styles.link} href="/privacy-policy" target="_blank">
                                                    Политикой конфиденциальности
                                                </a>
                                                <span> по №152-ФЗ от 27.07.2006</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <div className={styles.btnWrap}>
                                        <Button
                                            className={styles.modalButton}
                                            type="submit"
                                            disabled={submitting || !isPermissionToProcess}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            disableElevation
                                        >
                                            {btnText}
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Form>
                    </div>
                ) : (
                    <div className={styles.modalSuccessWrap}>
                        <DoneIcon style={{margin: 'auto', marginBottom: 24, fontSize: 100}}/>
                        <h1 className={styles.modalTitle}>Заявка отправлена</h1>
                        <p className={styles.modalSubtitle}>Ожидайте звонка</p>
                    </div>
                )}
            </div>
        </Dialog>
    );
}
