const botToken = '5309527819:AAFAroT5MZzjL7bmPKwRBmflpUHEIBytssY';
const chatId = '-1001631584931';

const sendMessageUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

export async function sendRequestToChat(text: string): Promise<Response> {
    return fetch(sendMessageUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            chat_id: chatId,
            chat_type: 'private',
            parse_mode: 'HTML',
            text,
        }),
    });
}
