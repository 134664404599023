import React, {useState} from 'react';

import {Alert, Snackbar} from '@mui/material';

import {ContactsFormModal} from '../components/blocks/ContactsForm/ContactsForm';
import Footer from '../components/blocks/Footer/Footer';
import HeaderDesktop from '../components/blocks/HeaderDesktop/HeaderDesktop';
import MenuMobile from '../components/blocks/MenuMobile/MenuMobile';

import styles from './MainLayout.module.scss';

interface Props {
    children: any;
}

export default function MainLayout({children}: Props) {

    const [isCookiesOpen, setCookiesOpen] = useState<boolean>(
        localStorage.getItem('cookies_accept') === null,
    );

    const handleCloseCookies = () => {
        setCookiesOpen(false);
        localStorage.setItem('cookies_accept', 'true');
    };

    return (
        <div className={styles.layout}>
            <MenuMobile/>
            <HeaderDesktop/>
            <main>
                {children}
            </main>
            <ContactsFormModal/>
            <Snackbar
                className={styles.snackbar}
                open={isCookiesOpen}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                onClose={handleCloseCookies}
            >
                <Alert className={styles.snackbarInner} severity="info" onClose={handleCloseCookies}>
                    <span>Мы используем файлы cookies и сервис веб-аналитики "Яндекс Метрика". Используя этот сайт, вы </span>
                    <a className={styles.cookiesLink} href="/consent-personal" target="_blank">
                        соглашаетесь на обработку персональных данных
                    </a>
                    <span> в соответствии с условиями </span>
                    <a className={styles.cookiesLink} href="/privacy-policy" target="_blank">
                        Политики конфиденциальности
                    </a>.
                </Alert>
            </Snackbar>
            <Footer/>
        </div>
    );
}
